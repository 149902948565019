import { render, staticRenderFns } from "./Brands.vue?vue&type=template&id=f259168c&scoped=true"
import script from "./Brands.vue?vue&type=script&lang=js"
export * from "./Brands.vue?vue&type=script&lang=js"
import style0 from "./Brands.vue?vue&type=style&index=0&id=f259168c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f259168c",
  null
  
)

export default component.exports