<template>
    <swiper :options="swiperOption" class="mySwiper">
        <swiper-slide v-for="(imageSrc, imageName) in sortedImages" :key="imageName">
            <img :src="`/images/brands/${imageSrc}`" :alt="`Slide ${imageName}`" style="height:50px" />
        </swiper-slide>
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    name: 'SwiperExampleMultipleSlidesPerView',
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            images: Array.from({ length: 20 }, (_, i) => `${i  + 1}.jpg`),

            swiperOption: {
                slidesPerView: 2,
                spaceBetween: 10,
                loop: true,
                autoplay: {
                    delay: 0,
                    disableOnInteraction: false
                },
                speed: 3000,
                loopAdditionalSlides: 30,
                freeMode: true,
                freeModeMomentum: false,
                freeModeMomentumBounce: false,
                freeModeSticky: false,
                breakpoints: {
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 10
                    },
                    1024: {
                        slidesPerView: 7,
                        spaceBetween: 10
                    }
                }
            }
        }
    },
    computed: {
        sortedImages() {
            // Convertimos el objeto en un array de entradas [key, value] y lo ordenamos numéricamente
            let entries = Object.entries(this.images);

            // Ordenamos numéricamente los nombres de las imágenes
            entries = entries.sort(([keyA], [keyB]) => {
                const numA = parseInt(keyA.split('.')[0]);
                const numB = parseInt(keyB.split('.')[0]);
                return numA - numB;
            });

            // Volver a crear el objeto ordenado
            const orderedImages = Object.fromEntries(entries);

            return orderedImages;
        }
    
    }
}
</script>

<style scoped>
.mySwiper {
    width: 100%;
    height: 100%;
    box-shadow: 3px 2px 4px 1px #e5e5e5;
    padding: 10px;
}
@media (prefers-color-scheme: dark) {
  .mySwiper{
    background:#fff;
    box-shadow: 3px 2px 4px 1px #000;

}
}

</style>
