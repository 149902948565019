<template>
    <div>
        <h1>Desarrollo y ejecución</h1>
        <div class="subtitle">En Quo no solo desarrollamos estrategias de Marketing, ¡También las hacemos realidad!
        </div>
        <p>Una vez que la estrategia está en su lugar, pasamos a la fase de ejecución.
            <br>
            Nos especializamos en llevar las ideas desde la concepción hasta la implementación, ayudando a las marcas a
            alcanzar sus objetivos.
            Nos aseguramos de que cada iniciativa esté perfectamente coordinada y ejecutada con precisión para obtener
            resultados óptimos.
        </p>
        <section class="images-section" v-if="!$isMobile()">
            <img src="@/assets/proyectos/pub-1.jpg" @click="openVideo('rcb-s_-xns8')" alt="Ejemplo 1 "
                class="info-image">
            <img @click="openVideo('SaWfSAra9YU')" src="@/assets/proyectos/pub-2.jpg" alt="Ejemplo 1 "
                class="info-image">
            <img @click="openVideo('L1WLT8ZrLZ0')"  src="@/assets/proyectos/pub-4.jpg" alt="Ejemplo 1 "
                class="info-image">
        </section>


        <div v-if="$isMobile()">
            <swiper class="swiper1" :options="swiperOption">
                <swiper-slide> <img src="@/assets/proyectos/pub-1.jpg" alt="Ejemplo 1 " class="info-image-m"
                        @click="openVideo('rcb-s_-xns8')"></swiper-slide>
                <swiper-slide> <img src="@/assets/proyectos/pub-2.jpg" alt="Ejemplo 1 " class="info-image-m"
                        @click="openVideo('SaWfSAra9YU')"></swiper-slide>
                <swiper-slide> <img src="@/assets/proyectos/pub-4.jpg" alt="Ejemplo 1 " class="info-image-m"
                        @click="openVideo('L1WLT8ZrLZ0')"></swiper-slide>

                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>

        <modal-video :channel="videoChannel" :videoId="currentVideoId" :isOpen.sync="videoIsOpen" />
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import ModalVideo from '@/components/modal-video';

export default {
    name: 'swiper-example-pagination',
    components: {
        Swiper,
        SwiperSlide,
        ModalVideo
    },
    data() {
        return {
            videoIds: [], // Almacena varios IDs de videos
            currentVideoId: '', // ID del video que se está reproduciendo
            videoIsOpen: false,
            swiperOption: {
                pagination: {
                    el: '.swiper-pagination'
                }
            }
        }
    },
    computed: {
        videoChannel() {
            return 'youtube';
        }
    },
    methods: {
        openVideo(videoId) {
            this.currentVideoId = videoId;
            this.videoIsOpen = true; // Abre el modal
        }
    }
}
</script>

<style lang="scss" scoped>
.info-image{
    cursor: pointer;
}
.swiper1 {
    height: 250px;
    width: 100%;
    margin-top: 20px;
}

.images-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0px auto;
    gap: 5px;
    margin-left: -32px;
    z-index: 2;
    position: relative;
    // margin-top: 80px;
    /* Espacio entre las imágenes */
}

.info-image-m {
    /* display: block;
    width: 100%; */
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.info-image {
    flex: 1;
    height: auto;
    object-fit: cover;
    width: calc(33.333% - 10px);
    /* Calcula el ancho de cada imagen restando el espacio entre ellas */
}

h1 {
    color: #009ee2;
    font-size: 40px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 80px;
}

.subtitle {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: bold;
}

p {
    text-align: center;
    font-size: 16px;
    line-height: 1.2;

    max-width: 1000px;
    display: block;
    margin: 0 auto;
    color: #656565;

}

.image-container {
    display: block;
    width: 100%;
    max-width: 1000px;
    margin: 80px auto;
}


@media screen and (max-width: 768px) {
    .images-section {
        flex-direction: column;
    }

    .info-image {
        width: 100%;
        margin: 5px 0;
    }

    .container {

        padding: 20px;
    }

    .title {

        max-width: 100%;

    }

    .info-box {
        width: 100%;

        padding: 0px;

    }

    .info-box-r {
        width: 100%;

        padding: 0px;

        float: left;
    }

    .info-title {
        font-weight: 700;
        font-size: 18px;
        z-index: 1;
        position: relative;

    }

    .info-section {
        /* display: flex;
                                    justify-content: space-between; */
        padding: 0;
        margin-bottom: 40px;
        position: relative;
    }


}

@media screen and (max-width: 768px) {
    h1 {
        color: #009ee2;
        font-size: 40px;
        text-align: left;
        margin-bottom: 10px;
        margin-top: 60px;
        margin-left: 20px;
        line-height: 1.2;
    }

    .subtitle {
        text-align: left;
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: bold;
        margin-left: 20px;
        width: 90%;
    }

    p {
        text-align: left;
        font-size: 18px;
        line-height: 1.2;
        margin-left: 20px;

        max-width: 90%;
        display: block;
        margin: 0 auto;
        color: #656565;
        margin-left: 20px;

    }

    .image-container {
        display: block;
        width: 80%;
        max-width: 1000px;
        margin: 20px auto;

    }

    //         .image-container img{
    // height: 300px;
    //     object-fit: cover;
    //         }

}
</style>