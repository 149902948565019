<template>
  <div class="home">
    <Hero />
    <Brands />
    <div class="fondo-circulos">
      <About />
      <Experiencia />
      <Services />
      <ContactForm />
    </div>

  </div>
</template>

<script>
import Hero from '@/components/home/Hero.vue';
import Brands from '@/components/home/Brands.vue';
import About from '@/components/home/About.vue';
import Experiencia from '@/components/home/Experiencia.vue';
import Services from '@/components/home/Services.vue';
import ContactForm from '@/components/home/ContactForm.vue';

export default {
  name: 'Home',
  components: {
    Hero,
    Brands,
    About,
    Experiencia,
    Services,
    ContactForm
  } 
};
</script>

<style scoped>
.fondo-circulos{
  background: url(~@/assets/fondo_puntos.png) repeat-y;
  background-size: contain;
  /* height: 600px; */
  /* overflow: hidden; */
}
</style>
