<template>
    <div class="galeria-content">
        <div class="log-galeria">
            <swiper :options="swiperOptionTop" class="gallery-top" ref="swiperTop" @slideChange="onSlideChange">
                <swiper-slide v-for="(slide, index) in slides" :key="index">
                    <template v-if="slide.type === 'video'">
                        <div class="video-container">
                            <iframe :id="'player-' + index" :src="getYouTubeEmbedUrl(slide.src)" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen>
                            </iframe>
                        </div>
                    </template>
                    <template v-else>
                        <div
                            :style="'background-image: url(' + slide.src + '); background-size: cover; background-position: center center; height: 100%;'">
                        </div>
                    </template>
                </swiper-slide>

                <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            </swiper>
        </div>

        <div class="mini">
            <div class="image-grid">
                <div class="grid-item" v-for="(slide, index) in slides" :key="index"
                    :class="{ active: activeIndex === index }" :style="getGridItemStyle(slide)"
                    @click="goToSlide(index)">
                    <template v-if="slide.type === 'video'">
                        <img src="@/assets/video.png" alt="Play Icon" class="play-icon">
                    </template>
                </div>
            </div>
        </div>
        <div class="galeria-footer"></div>
    </div>
</template>

<script>
/* global YT */
export default {
    props: {
        carpeta: String,
        videos: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            images: Array.from({ length: 12 }, (_, i) => `${i + 1}.jpg`),
            swiperOptionTop: {
                spaceBetween: 20,
                effect: "fade",
                loop: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                }
            },
            activeIndex: 0,
            youTubePlayers: {}
        };
    },
    computed: {
        slides() {
            const totalSlides = Math.max(this.images.length, this.videos.length);
            const slidesArray = [];

            for (let i = 0; i < totalSlides; i++) {
                const video = this.videos.find(video => video.index === i);
                if (video) {
                    slidesArray.push({
                        type: 'video',
                        src: video.url,
                        index: i
                    });
                } else {
                    slidesArray.push({
                        type: 'image',
                        src: `/images/${this.carpeta}/${this.images[i % this.images.length]}`,
                        index: i
                    });
                }
            }
            return slidesArray;
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.swiperTop = this.$refs.swiperTop.$swiper;
            this.initYouTubeAPI();
        });
    },
    methods: {
        goToSlide(index) {
            this.swiperTop.slideToLoop(index, 0);
        },
        onSlideChange() {
            this.activeIndex = this.swiperTop.realIndex;
            const currentSlide = this.slides[this.activeIndex];

            if (currentSlide.type === 'video') {
                // Detener el autoplay del swiper
                this.swiperTop.autoplay.stop();

                // Reproducir el video
                this.playYouTubeVideo(this.activeIndex);
            } else {
                // Iniciar el autoplay del swiper
                this.swiperTop.autoplay.start();

                // Detener todos los videos
                this.stopAllYouTubeVideos();
            }
        },
        getGridItemStyle(slide) {
            if (slide.type === 'video') {
                return {
                    'background-image': `url('https://img.youtube.com/vi/${this.getYouTubeVideoID(slide.src)}/hqdefault.jpg')`,
                    'background-size': 'cover',
                    'background-position': 'center center'
                };
            } else {
                return {
                    'background-image': `url(${slide.src})`,
                    'background-size': 'cover',
                    'background-position': 'center center'
                };
            }
        },
        getYouTubeEmbedUrl(url) {
            const videoID = this.getYouTubeVideoID(url);
            return `https://www.youtube.com/embed/${videoID}?enablejsapi=1&rel=0&autoplay=1&mute=1`;
        },
        getYouTubeVideoID(url) {
            const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|shorts\/)([^#&?]*).*/;
            const match = url.match(regExp);
            return (match && match[2].length === 11) ? match[2] : null;
        },
        initYouTubeAPI() {
            if (typeof YT === 'undefined' || typeof YT.Player === 'undefined') {
                window.onYouTubeIframeAPIReady = this.setupYouTubePlayers;
                const tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";
                document.head.appendChild(tag);
            } else {
                this.setupYouTubePlayers();
            }
        },
        setupYouTubePlayers() {
            this.slides.forEach((slide, index) => {
                if (slide.type === 'video') {
                    const playerId = `player-${index}`;
                    this.youTubePlayers[index] = new YT.Player(playerId, {
                        events: {
                            'onStateChange': this.onPlayerStateChange
                        }
                    });
                }
            });
        },
        playYouTubeVideo(index) {
            const player = this.youTubePlayers[index];
            if (player && player.playVideo) {
                player.playVideo();
            }
        },
        stopAllYouTubeVideos() {
            Object.values(this.youTubePlayers).forEach(player => {
                if (player && player.pauseVideo) {
                    player.pauseVideo();
                }
            });
        },
        onPlayerStateChange(event) {
            // Opcional: manejar cambios en el estado del reproductor
        }
    }
};
</script>

<style scoped>
.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 20px;
    height: 600px;
    position: relative;
}

.grid-item {
    background-size: cover;
    background-position: center;
    cursor: pointer;
    border-radius: 12px;
    filter: grayscale(100%);
    transition: filter 0.3s ease;
    position: relative;
}

.grid-item.active {
    filter: grayscale(0%);
}

.grid-item .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    /* Ajusta el tamaño según tus necesidades */
    height: 50px;
    /* Ajusta el tamaño según tus necesidades */
    pointer-events: none;
}

.mini {
    width: 39%;
    float: left;
    padding: 20px;
    padding-right: 0;
}

.log-galeria {
    width: 59%;
    height: 600px;
    float: left;
    margin-top: 20px;
    border-radius: 8px;
    overflow: hidden;
}

.galeria-footer {
    background: #FFF;
    height: 600px;
    margin-bottom: 200px;
}

.galeria-content {
    margin: 0 auto;
    margin-bottom: 150px;
}

.swiper-container {
    background-color: transparent;
}

.swiper-slide {
    background-size: cover !important;
    background-position: center center !important;
}

.gallery-top {
    height: 100% !important;
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;
    overflow: hidden;
}

.video-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1056px) {
    .mini {
        width: 100%;
        margin-top: 20px;
        padding: 0;
        margin-bottom: 30px;
    }

    .log-galeria {
        width: 100%;
        height: 50vh;
    }

    .image-grid {
        gap: 10px;
        height: 400px;
    }

    .galeria-footer {
        height: 10px;
        margin-bottom: 10px;
    }
}
</style>
